import { DateTime } from 'luxon';
import React, { ReactElement, useContext, useState } from 'react';
import 'yup-phone';
import { AppActionTypes, AppContext } from '../../AppContextProvider';
import Button from '../../components/Button/Button';
import Modal from '../../components/Modal/Modal';
import { ChurchStatus } from '../../enums/ChurchStatus';
import { useTranslationByKey } from '../../hooks/use-translation-by-key';
import { ChurchListActionTypes, ChurchListContext } from '../ChurchList/ChurchListContextProvider';
import { reGenerateReport } from '../../hooks/re-generate-report';
import './ChurchCloseSurveyModal.scss';
import supabase from '../../supabaseClient';
import { classToPlain } from 'class-transformer';

export default function ChurchCloseSurveyModal(): ReactElement {
  const t = useTranslationByKey('CONTAINERS.CHURCH_CLOSE_SURVEY_MODAL');

  const { state, dispatch } = useContext(AppContext);
  const { dispatch: churchListDispatch } = useContext(ChurchListContext);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const church = state.selectedChurch ?? null;

  const closeModal = (): void => {
    dispatch({ type: AppActionTypes.CloseChurchCloseSurveyModal });
  };

  const confirmClosingSurvey = async (): Promise<void> => {
    dispatch({ type: AppActionTypes.OpenProgressBarModal });
    setIsSubmitting(true);

    const churchId = state.selectedChurch.churchId;
    church.status = ChurchStatus.COACHING;
    const dateTimeNow = DateTime.now();
    church.surveyForm.surveyDate.closeDate = dateTimeNow;
    church.surveyForm.result.isSurveyCompleted = true;

    const { error } = await supabase
      .from('churches')
      .update({
        status: ChurchStatus.COACHING,
        survey_form: classToPlain(church.surveyForm),
        survey_form_close_date: dateTimeNow
      })
      .eq('church_id', churchId);

    console.log('error :>> ', error);
    dispatch({
      type: AppActionTypes.ReRunChurchReport,
      payload: { church: state.selectedChurch }
    });

    await reGenerateReport(
      church.churchId.toString(),
      church.surveyForm.id,
      church.surveyForm.collector.id,
      true
    );

    dispatch({
      type: AppActionTypes.ReRunChurchReport,
      payload: { church: state.selectedChurch }
    });

    if (error) {
      console.error('Error updating church:', error);
      // Handle error (e.g., show error message)
    } else {
      // Handle success (e.g., close modal, show success message)
      closeModal();
    }
    churchListDispatch({ type: ChurchListActionTypes.TriggerFetch });
    setIsSubmitting(false);
  };

  return (
    <Modal
      open={state.isChurchCloseSurveyModalOpened}
      toggleClose={closeModal}
      className="church-close-survey-modal"
      title={t('TEXT_TITLE')}
    >
      <>
        <p className="confirm-text">{t('TEXT_CONFIRM_TEXT')}</p>
        <div className="buttons-holder">
          <Button color="red" size="small" onClick={closeModal}>
            {t('TEXT_NO_TEXT')}
          </Button>
          <Button
            color="green"
            size="small"
            onClick={confirmClosingSurvey}
            isLoading={isSubmitting}
          >
            {t('TEXT_YES_TEXT')}
          </Button>
        </div>
      </>
    </Modal>
  );
}

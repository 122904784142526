import { plainToClass } from 'class-transformer';
import React, { ReactElement, useContext, useState, useEffect } from 'react';
import 'yup-phone';
import { AppActionTypes, AppContext } from '../../AppContextProvider';
import Modal from '../../components/Modal/Modal';
import {
  ChurchListActionTypes,
  ChurchListContext
} from '../../containers/ChurchList/ChurchListContextProvider';
import { useTranslationByKey } from '../../hooks/use-translation-by-key';
import { Progress } from '../../models/Progress/Progress';
import './ProgressBar.scss';
import supabase from '../../supabaseClient';
import { Church } from '../../models/church/Church';

export default function ProgressBar(): ReactElement {
  const t = useTranslationByKey('CONTAINERS.CHURCH_REPORT_MODAL.PROGRESS_MODAL');
  const { state } = useContext(ChurchListContext);
  const { state: appState, dispatch } = useContext(AppContext);
  const { dispatch: churchListDispatch } = useContext(ChurchListContext);
  const selectedChurch = state.selectedChurch ? state.selectedChurch : null;
  const [maxValue, setMaxValue] = useState(null);
  const [currentValue, setCurrentValue] = useState(0);

  const closeModal = (): void => {
    dispatch({ type: AppActionTypes.CloseProgressBarModal });
  };

  useEffect(() => {
    setTimeout(() => {
      const checkProgress = async () => {
        const { data: query } = await supabase
          .from('progress')
          .select('*')
          .eq('church', selectedChurch.name);

        let progress;
        if (query?.length) {
          progress = plainToClass(Progress, query[0]);
        }
        if (maxValue === currentValue || !progress) {
          closeModal();

          const { data: updatedChurch } = await supabase
            .from('churches')
            .select('*')
            .eq('church_id', selectedChurch.churchId)
            .single();

          const mappedChurch: Church = plainToClass(Church, updatedChurch);

          churchListDispatch({
            type: ChurchListActionTypes.SetSelectedChurch,
            payload: { church: mappedChurch }
          });
          churchListDispatch({ type: ChurchListActionTypes.TriggerFetch });
        } else {
          if (!maxValue) {
            setMaxValue(progress?.max);
          }
          setCurrentValue(progress?.value);
        }
      };
      checkProgress();
    }, 10000);
  }, [maxValue, currentValue]);
  return (
    <Modal
      open={appState.isProgressBarModalOpened}
      toggleClose={closeModal}
      className="church-close-survey-modal"
      title={t('TEXT_TITLE')}
    >
      <>
        <progress className="progress-holder" value={currentValue || 0} max={maxValue || 0} />
        <br />
        <div className="loading-spinner"></div>{' '}
        {maxValue && currentValue ? (
          <span>{Math.round((currentValue / maxValue) * 100)}%</span>
        ) : (
          <span>0%</span>
        )}
      </>
    </Modal>
  );
}

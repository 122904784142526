import { Push, push } from 'connected-react-router';
import React, { ReactElement, ReactNode, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AppActionTypes, AppContext } from '../../AppContextProvider';
import Button from '../../components/Button/Button';
import Dropdown from '../../components/Dropdown/Dropdown';
import Icon from '../../components/Icon/Icon';
import { useTranslationByKey } from '../../hooks/use-translation-by-key';
import { SelectItem } from '../../models/SelectItem';
import AddTeamMemberModal from '../AddTeamMemberModal/AddTeamMemberModal';
import { Language } from '../../enums/Language';
import './AppHeader.scss';
import supabase from '../../supabaseClient';
import { useSupabaseUser } from '../../useSupabaseUser';

interface Props {
  push: Push;
}

function AppHeader(props: Props): ReactElement {
  const user = useSupabaseUser();
  const { state, dispatch } = useContext(AppContext);
  const t = useTranslationByKey('CONTAINERS.APP_HEADER');
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const languages = i18n.languages;

  const getIcon = (lang: string): ReactNode => {
    switch (lang) {
      case 'en':
        return <Icon variant="en" />;
      case 'es':
        return <Icon variant="es" />;
    }
  };

  const getLanguageLabel = (lang: string): ReactNode => (
    <span className="app-header-lang">
      {getIcon(lang)}
      {Language[lang]}
    </span>
  );

  const [isAddTeamMemberModalOpen, setIsAddTeamMemberModalOpen] = useState<boolean>(false);
  const languageSelectValues = languages.map(
    (lang) => new SelectItem(lang, getLanguageLabel(lang))
  );

  const dropdownValues = [new SelectItem('logout', t('TEXT_LOGOUT'))];

  const onItemSelected = async (item: SelectItem) => {
    if (item.id === 'logout') {
      await supabase.auth.signOut();
      props.push('/login');
    }
  };

  const onChangeLanguage = (item: SelectItem) => {
    localStorage.setItem('language', item.id);
    i18n.changeLanguage(item.id);
  };

  return (
    <div className="app-header">
      <div className="container">
        <div className="app-header-content">
          <img className="app-header-logo" src={'/img/peak-logo.png'} alt={'PEAK'} />

          {(state.userClaims?.isAdmin ||
            state.userClaims?.isFullAccessUser ||
            process.env.REACT_APP_WEB_URL === 'https://peak-testing.web.app' ||
            process.env.REACT_APP_WEB_URL === 'https://peak-staging-340913.web.app') && (
            <>
              <Button
                onClick={() => dispatch({ type: AppActionTypes.OpenEditChurchModal })}
                color="blue-gray"
                size="small"
              >
                {t('BUTTON_ADD_NEW_CHURCH')}
              </Button>
              <Button
                color="blue-gray"
                size="small"
                onClick={() => setIsAddTeamMemberModalOpen(true)}
              >
                {t('BUTTON_ADD_TEAM_MEMBER')}
              </Button>
            </>
          )}
          <Dropdown items={languageSelectValues} selectItem={onChangeLanguage}>
            <span className="app-header-user-name">{getLanguageLabel(currentLanguage)}</span>
          </Dropdown>
          {user?.id && (
            <Dropdown items={dropdownValues} selectItem={onItemSelected}>
              <span className="app-header-user-name">{user.displayName ?? t('TEXT_UNKNOWN')}</span>
            </Dropdown>
          )}
        </div>
      </div>
      <AddTeamMemberModal
        open={isAddTeamMemberModalOpen}
        toggleClose={() => setIsAddTeamMemberModalOpen(false)}
      />
    </div>
  );
}

export default connect(null, { push })(AppHeader);
